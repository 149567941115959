import { Suspense, lazy } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

// ** Spinner (Splash Screen)
import Spinner from "./components/Loading";

// bootstrap
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";

// ** template files
import "./assets/scss/style.scss";
import "./assets/js/app.js";

// Tiny slider
import "./assets/lib/tiny-slider/css/tiny-slider.css";
import "./assets/lib/tiny-slider/js/tiny-slider";

const App = lazy(() => import("./App"));

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <Suspense fallback={<Spinner />}>
      <App />
    </Suspense>
  </BrowserRouter>
);
